import {
    CartesianGrid,
    Line,
    LineChart,
    XAxis,
    YAxis,
} from "recharts";

import {
    ChartConfig,
    ChartContainer,
    ChartLegend,
    ChartLegendContent,
    ChartTooltip,
    ChartTooltipContent,
} from "../ui/chart";


const chartData = [
    // {state: "Maharashtra", mitigated: 28774, pending: 32012},
    {state: "Uttar Pradesh", mitigated: 27312, pending: 34149},
    // {state: "Andhra Pradesh", mitigated: 25123, pending: 31021},
    // {state: "Jharkhand", mitigated: 19862, pending: 27821},
    // {state: "Karnataka", mitigated: 26921, pending: 17890},

];


const chartConfig = {
    mitigated: {
        label: "Cases Mitigated",
        color: "red",
    },
    pending: {
        label: "Cases in Court",
        color: "gray",
    },
} satisfies ChartConfig;

export default function CaseGraphComponents() {
    return (
        <>
            <div className="bg-white theme-box-shadow rounded-lg p-4 xl:w-[60%]">
                <div className="flex ">
                    <h4>State-Wise Cases</h4>
                </div>
                <div className="section-margin ">
                    <div className="overflow-x-auto">

                        <ChartContainer
                            config={chartConfig}
                            className="aspect-auto h-[327px] w-full min-w-[500px]"
                        >
                            <LineChart
                                accessibilityLayer
                                data={chartData}
                                margin={{
                                    left: 0,
                                    right: 0,
                                }}
                            >
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="1" y2="0">
                                        <stop offset="2.61%" stopColor="#FA829E" stopOpacity={1}/>
                                        {/*<stop offset="30%" stopColor="#FA829E" stopOpacity={0.5}/>*/}
                                        {/*<stop offset="95%" stopColor="#FFAA17" stopOpacity={0.5}/>*/}
                                        <stop offset="100%" stopColor="#FFAA17" stopOpacity={1}/>
                                    </linearGradient>
                                </defs>

                                <CartesianGrid vertical={false}/>
                                <XAxis
                                    dataKey="state"
                                    tickLine={false}
                                    axisLine={false}
                                    tickMargin={8}

                                />
                                <YAxis
                                    tickLine={false}
                                    axisLine={true}
                                    // tickFormatter={(value) => {
                                    //     let formatter = Intl.NumberFormat('en', {notation: 'compact'});
                                    //     return formatter.format(value)
                                    // }}
                                />
                                <ChartTooltip
                                    cursor={false}
                                    content={<ChartTooltipContent className="bg-white"/>}
                                />

                                <Line
                                    dataKey="pending"
                                    type="monotone"
                                    stroke="#bbc6cf"
                                    strokeWidth={2}
                                    dot={false}
                                    strokeDasharray="5 5"
                                />
                                <Line
                                    dataKey="mitigated"
                                    type="monotone"
                                    // fill="url(#colorUv)"
                                    stroke="#22c55e"
                                    // stroke="red"
                                    strokeWidth={4}
                                    dot={false}
                                />
                            </LineChart>
                        </ChartContainer>
                    </div>
                    <div className="flex justify-end section-margin">
                        <ul className="flex justify-between gap-4">
                            <li className="relative pl-6">
                              <span className="absolute h-[5px] w-[20px] rounded-lg bg-green-500 -translate-y-1/2 top-1/2 left-0 "></span>
                                Cases Mitigated
                            </li>
                            <li className="relative pl-6">
                                <span className="absolute h-[1px] w-[20px] border-t-2 border-[#bbc6cf] border-dashed -translate-y-1/2 top-1/2 left-0"></span>
                                Cases in Court
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </>
    );
}
