"use client";
import { Button } from "../ui/button";
import React, { useRef, useState } from "react";
import loginpic from "../../Assets/image/statics/loginimg.png";
import loginbg from "../../Assets/image/background/login.png";
import logo from "../../Assets/image/logo/contrast_logo.png";
import  RequiredTemplate  from "../Helper/form/required.form";


type fields = {
  label: string;
  otp: string[];
  setOtp: React.Dispatch<React.SetStateAction<string[]>>;
  inputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>;
  handleChange: any;
  handleKeyDown: any;
};

function OtpInput({
  label,
  otp,
  setOtp,
  inputRefs,
  handleChange,
  handleKeyDown,
}: fields) {
  return (
    <div className="flex flex-col gap-2">
      <label>
        {label}
        <RequiredTemplate required={true} />
      </label>
      <div className="grid grid-cols-6 gap-2">
        {otp.map((item: string, i: number) => (
          <input
            type="number"
            ref={(ele) => {
              inputRefs.current[i] = ele;
            }}
            onFocus={(e) => e.target.select()}
            onChange={(e) => handleChange(e, i, otp, setOtp)}
            onKeyDown={(e) => handleKeyDown(e, i, otp, inputRefs)}
            key={i}
            className="h-[3rem] w-[3rem] rounded-sm p-4 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none shadow-[0px_0px_4px_0px_rgba(0,0,0,0.25)]"
            value={item}
            maxLength={1}
          />
        ))}
      </div>
      <div className="flex justify-end items-center w-full">
        <span>Resend OTP</span>
      </div>
    </div>
  );
}

export default function Page() {
  const [mobileOtp, setMobileOtp] = useState(new Array(6).fill(""));
  const [emailOtp, setEmailOtp] = useState(new Array(6).fill(""));

  const mobileInputRefs = useRef<(HTMLInputElement | null)[]>(
    new Array(6).fill(null)
  );
  const emailInputRefs = useRef<(HTMLInputElement | null)[]>(
    new Array(6).fill(null)
  );

  const handleChange = (
    e: any,
    i: number,
    otp: string[],
    setOtp: React.Dispatch<React.SetStateAction<string[]>>,
    inputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>
  ) => {
    let value = e.target.value;
    const newOtp = [...otp];
    newOtp[i] = value;
    setOtp(newOtp);

    if (value !== "" && i < otp.length - 1) {
      inputRefs.current[i + 1]?.focus();
    }
  };

  const handleKeyDown = (
    e: any,
    i: number,
    otp: string[],
    inputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>
  ) => {
    if (e.key === "Backspace" && !otp[i] && i > 0) {
      inputRefs.current[i - 1]?.focus();
    }
  };

  return (
    <div className="flex flex-row   h-[100svh]">
      <div className="basis-1/2 flex justify-center  items-center bg-white ">
        <div>
          <div className="w-full flex flex-col gap-6 p-[5rem]  max-w-[40rem]">
            <div className="flex items-center justify-center ">
              <img src={logo} alt="logo" className="" />
            </div>
            <p className="text-center text-sm">
              Enter six digit OTP we’ve sent on your mobile <br></br>
              number “XXXXXXXX78” and email id durXXXXXXXXXmail.com
            </p>

            <form className="flex flex-col gap-3">
              <OtpInput
                label="Enter Mobile Otp"
                otp={mobileOtp}
                setOtp={setMobileOtp}
                inputRefs={mobileInputRefs}
                handleChange={(e: any, i: number) =>
                  handleChange(e, i, mobileOtp, setMobileOtp, mobileInputRefs)
                }
                handleKeyDown={(e: any, i: number) =>
                  handleKeyDown(e, i, mobileOtp, mobileInputRefs)
                }
              />
              <OtpInput
                label="Enter Email Otp"
                otp={emailOtp}
                setOtp={setEmailOtp}
                inputRefs={emailInputRefs}
                handleChange={(e: any, i: number) =>
                  handleChange(e, i, emailOtp, setEmailOtp, emailInputRefs)
                }
                handleKeyDown={(e: any, i: number) =>
                  handleKeyDown(e, i, emailOtp, emailInputRefs)
                }
              />

              <Button
                className="rounded-none uppercase font-bold w-full"
                size="long"
                variant="default"
              >
                Next
              </Button>
            </form>
          </div>
        </div>
      </div>
      <div
        className="basis-1/2 bg-no-repeat bg-cover bg-center flex items-center justify-center"
        style={{ backgroundImage: `url(${loginbg})` }}
      >
        <img src={loginpic} alt="Logo" />
      </div>
    </div>
  );
}
