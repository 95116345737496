import loginbg from "../../Assets/image/background/login.png";
import logo from "../../Assets/image/logo/logon1.png";
import loginpic from "../../Assets/image/statics/loginimg.png";
import { InputForm } from "../Helper/form/input.form";
import { Button } from "../ui/button";
import { LoginComponent } from "./login.component";
import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function Login() {
  const Navigate = useNavigate();
  const [passwordview, setPasswordview] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleLoginWithOtp = () => {
    Navigate("/login_with_otp");
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (email === "Nic_admin" && password === "nic@12345") {
      Swal.fire({
        title: "Login Successful",
        icon: "success",
        confirmButtonText: "Okay",
        customClass: {
          confirmButton: "swal-custom-btn",
          title: "swal-custom-title",
          popup: "swal-custom-popup",
        },
      }).then(() => {
        Navigate("/");
      });
    } else {
      // Show failure message
      Swal.fire({
        title: "Something went wrong!",
        icon: "error",
        confirmButtonText: "Okay",
        customClass: {
          confirmButton: "swal-custom-btn",
          title: "swal-custom-title",
          popup: "swal-custom-popup",
        },
      });
    }
  };
  return (
    <>
      <LoginComponent>
        <form className=" flex flex-col gap-3" onSubmit={handleSubmit}>
          <h4 className="text-[#FFAA17] font-semibold mb-3">Login</h4>

          <InputForm
            placeholder={"Enter registered email or mobile"}
            readonly={false}
            dataBlur={(val) => {}}
            dataChange={(val) => setEmail(val)}
            name="Email or Mobile"
            type="string"
            label="Email or Mobile"
            labelcolor=""
            //   min={100}
            //   max={100}
          />
          <InputForm
            eyebutton={true}
            placeholder={"Enter password"}
            readonly={false}
            dataBlur={(val) => {}}
            dataChange={(val) => setPassword(val)}
            name="Password"
            showpassword={(val) => {
              setPasswordview(val);
            }}
            passvisble={passwordview}
            type={passwordview ? "Password" : "text"}
            label="Password"
            labelcolor=""
            //   min={100}
            //   max={100}
          />
          <div className="flex items-center justify-end my-2 text-sm font-semibold cursor-pointer">
            <span className="">FORGOT PASSWORD?</span>
          </div>
          <Button
            className="rounded-md uppercase border-none font-bold w-full h-12"
            size="long"
            variant="default"
          >
            submit
          </Button>
          <Button
            className="rounded-md uppercase border-black font-bold w-full h-12 bg-white hover:bg-gray-100"
            size="long"
            variant="default"
            onClick={handleLoginWithOtp}
          >
            Login with OTP
          </Button>
        </form>
        <div className="mt-4 tracking-tight flex items-center justify-center">
          <p>
            Not have an account?{" "}
            <Link
              to={"/register"}
              className="text-[#093FC7] underline decoration-1"
            >
              Register Here
            </Link>
          </p>
        </div>
      </LoginComponent>
    </>
  );
}
