import React, { useRef, useState } from "react";
import { InputForm } from "../Helper/form/input.form";
import { Button } from "../ui/button";
import { Link,  useNavigate } from "react-router-dom";
import loginpic from "../../Assets/image/statics/loginimg.png";
import loginbg from "../../Assets/image/background/login.png";
import logo from "../../Assets/image/logo/logon1.png"
import { LoginComponent } from "./login.component";
import Swal from "sweetalert2";

export default function Page() {
  const Navigate = useNavigate()
  const [fullName, setfullName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit =((e:any)=>{
    e.preventDefault();
    if (fullName !=="" && mobile !=="" && email !== "") {
     
        Navigate("/enter_register_otp")
      
    } else {
      // Show failure message
      Swal.fire({
        title: "Something went wrong!",
        icon: "error",
        confirmButtonText: "Okay",
        customClass: {
          confirmButton: "swal-custom-btn",
          title: "swal-custom-title",
          popup: "swal-custom-popup",
        },
      });
    }
  })
  return (
    <>
      <LoginComponent>
      <form className=" flex flex-col gap-3" onSubmit={handleSubmit}>
              <h4 className="text-[#FFAA17] font-semibold mb-3">Registration</h4>

              <InputForm
                placeholder={"Enter registered Full Name"}
                readonly={false}
                dataBlur={(val) => {}}
                dataChange={(val) => setfullName(val)}
                name="Full Name"
                type="string"
                label="Full Name"
                labelcolor=""
                isRequired={true}
                //   min={100}
                //   max={100}
              />

              <InputForm
                placeholder={"Enter registered Mobile Number"}
                readonly={false}
                dataBlur={(val) => {}}
                dataChange={(val) => setMobile(val)}
                name="Email or Mobile"
                type="string"
                label="Mobile No."
                labelcolor=""
                isRequired={true}
                //   min={100}
                //   max={100}
              />

              <InputForm
                placeholder={"Enter registered Email"}
                readonly={false}
                dataBlur={(val) => {}}
                dataChange={(val) => setEmail(val)}
                name="Email or Mobile"
                type="string"
                label="Email Id"
                labelcolor=""
                isRequired={true}
                //   min={100}
                //   max={100}
              />
              
              <Button
                className="mt-4 rounded-md uppercase border-none font-bold w-full h-12"
                size="long"
                variant="default"
               
              >
                submit
              </Button>
            </form>
            <div className="mt-2 tracking-tight flex items-center justify-center">
              <p>
                Already have an account?{" "}
                <Link
                  to={"/login"}
                  className="text-[#093FC7] underline decoration-1"
                >
                Login Here
                </Link>
              </p>
            </div>
      </LoginComponent>
    </>
  );
}
