import React, { Children } from "react";
import { cn } from "../../lib/utils";
import logo from "../../Assets/image/logo/logon1.png"
import loginpic from "../../Assets/image/statics/loginimg.png";

export const LoginComponent = ({
    className,
    children,
}: {
    className?: string;
    children: React.ReactNode;
}) =>{
     return(
        <div className={cn("min-h-screen flex justify-center bg-white", className )}>
            <div className="w-full md:w-1/2 lg:w-2/5 tracking-wide">
                <div className="w-full flex flex-col gap-6 p-[2rem] sm:p-[4rem] md:p-[2rem] lg:p-[3rem] xl:p-[5rem]  max-w-[50rem]">
                    <div className="flex items-center justify-center">
                        <img 
                        src={logo} 
                        alt="logo" 
                        className=""
                        width={180}
                        height={70}
                        />
                    </div>
                        {children}
                </div>
            </div>
            
            <div className="w-0 md:w-1/2 lg:w-3/5 bg-[#F5E7D0] flex items-center">
                <img src={loginpic} alt="logo" className="m-auto" />
            </div>


        </div>
     )
}