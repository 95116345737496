import React from "react";
import Header from "../Components/Header/Header";
import Dashboard1 from "../Components/Dashboard1/Dashboard1";

const DashboardPage = () => {
    return(
        <>
        <Header/>
        <Dashboard1/>
        </>
    )
}

export default DashboardPage