export type TJudgementsData = {
    name: string
    link: string
    category: string
}

export const JudgementsData: TJudgementsData[] =
    [
        {
            name: "Whirlpool of India Ltd. Versus Union of India & Ors. Case No.7417 of 2001",
            link: "https://consumeraffairs.nic.in/sites/default/files/file-uploads/supreme-court/1535971876_whirlpool.pdf",
            category: "Supreme Court Judgements"
        },
        {
            name: "Jayanti Food Processing (P) Ltd. Versus Commissioner of Central Excise, Rajasthan. Case No. 2819 of 2002",
            link: "https://consumeraffairs.nic.in/sites/default/files/file-uploads/supreme-court/1535971912_jayanti%20food%20processing.pdf",
            category: "Supreme Court Judgements"
        },
        {
            name: "India Photographic Co. Ltd. Versus H.D. Shourie Dated 03/08/1999",
            link: "https://consumeraffairs.nic.in/sites/default/files/file-uploads/supreme-court/1535971949_indian%20photographic%20Co.pdf",
            category: "Supreme Court Judgements"
        },
        {
            name: "State of Kerala Versus Flora & Ors. criminal Appeal Nos. 963-965 OF 1999",
            link: "https://consumeraffairs.nic.in/sites/default/files/file-uploads/supreme-court/1535972031_Criminal%20Appeal%20No%20963-965.pdf",
            category: "Supreme Court Judgements"
        },
        {
            name: "Consumer Protection Council, Rourkela Versus Indian Oil Corporation Ltd. And Ors. Appeal No.10126 OF 2010",
            link: "https://consumeraffairs.nic.in/sites/default/files/file-uploads/supreme-court/1535972115_CPC%2C%20rourkela%20vs%20IOCL%20CA%20No_10126%20OF%202010.txt",
            category: "Supreme Court Judgements"
        },
        {
            name: "M/s FMI Ltd. & Anr. Versus Union of India & Ors. CWP No. 22996 of 2012 in HC Punjab & Haryana",
            link: "https://consumeraffairs.nic.in/sites/default/files/file-uploads/court_cases_and_judgements/1535970202_FMI%20vs%20UOI%20CWP%20No_%2022996%20of%202012%20in%20HC%20Punjab%20%26%20Haryana.pdf",
            category: "Other Court Cases & Judgements"
        },
        {
            name: "Godrej GE appliances WA.No. 218 of 2003(B) in kerala at Ernakulam",
            link: "https://consumeraffairs.nic.in/sites/default/files/file-uploads/court_cases_and_judgements/1535970282_Godrej%20GE%20appliances%20WA_No_%20218%20of%202003%28B%29%20in%20kerala%20at%20Ernakulam.txt",
            category: "Other Court Cases & Judgements"
        },
        {
            name: "Reebok India Company Versus Union of India & Others.CW14929 of 2006 at Delhi",
            link: "https://consumeraffairs.nic.in/sites/default/files/file-uploads/court_cases_and_judgements/1535970332_reebok%20india%20CW14929%20of%202006.pdf",
            category: "Other Court Cases & Judgements"
        },
        {
            name: "M/s FMI Ltd. & Anr. Versus Union of India & Ors. CWP No. 22996 of 2012 in HC Punjab & Haryana",
            link: "https://consumeraffairs.nic.in/sites/default/files/file-uploads/court_cases_and_judgements/1535976234_FMI%20vs%20UOI%20CWP%20No_%2022996%20of%202012%20in%20HC%20Punjab%20%26%20Haryana.pdf",
            category: "Other Court Cases & Judgements"
        },
        {
            name: "Godrej GE appliances WA.No. 218 of 2003(B) in kerala at Ernakulam",
            link: "https://consumeraffairs.nic.in/sites/default/files/file-uploads/court_cases_and_judgements/1535976279_Godrej%20GE%20appliances%20WA_No_%20218%20of%202003%28B%29%20in%20kerala%20at%20Ernakulam.txt",
            category: "Other Court Cases & Judgements"
        },
        {
            name: "Reebok India Company Versus Union of India & Others.CW14929 of 2006 at Delhi",
            link: "https://consumeraffairs.nic.in/sites/default/files/file-uploads/court_cases_and_judgements/1535976315_reebok%20india%20CW14929%20of%202006.pdf",
            category: "Other Court Cases & Judgements"
        },
        {
            name: "UOI & ORS. Versus National Restaurant Association at Delhi",
            link: "https://consumeraffairs.nic.in/sites/default/files/file-uploads/court_cases_and_judgements/1535976349_LM_High_Court.pdf",
            category: "Other Court Cases & Judgements"
        },
    ]