import Header from '../Components/Header/Header'
import LicenceRegistrationComponent from '../Components/LicenseRegistrationComponent/LicenseRegistrationComponent'

import React from 'react'

const LicenceRegistrationPage = () =>{
    return(
        <>
            <Header/>
            <LicenceRegistrationComponent/>
            
        </>
    )
}

export default LicenceRegistrationPage