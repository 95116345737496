
import {Bar, BarChart, CartesianGrid, XAxis, YAxis} from "recharts"

import {
    ChartConfig,
    ChartContainer,
    ChartLegend,
    ChartLegendContent,
    ChartTooltip,
    ChartTooltipContent,
} from "../../ui/chart"
import DropdownSelect2SingleSelect from "../../Helper/form/select2/select.dropdown";

const chartData = [
    { type: "Inspected", data: 640253, fill: "#9109FE"},
    { type: "Detected", data: 40085, fill: "#FFAA17"},
    { type: "Mitigated", data: 27312 , fill:"#3EC000"},
    { type: "Pending in Court", data: 34149 , fill:"#FF0505"},
]

const chartConfig = {
    data: {
        label: "",
    }
} satisfies ChartConfig

export default function CaseStaticsComponent(){
    return (
        <div className="bg-white theme-box-shadow rounded-lg p-4 relative">

            <div className="flex justify-between">
                <h4>State-Wise Pendency</h4>
                <div>
                    <DropdownSelect2SingleSelect
                        name={"state"}
                        dataOptions={[
                            {
                                label: "Uttar Pradesh",
                                value: "Uttar Pradesh",
                            },
                        ]}
                        selectedData={"Uttar Pradesh"}
                        onDataChange={(value: any) => { }}
                    />
                </div>

            </div>
            {/*<div className="absolute top-1/2 -translate-y-1/2 -rotate-90 -left-6  "><p className="font-bold text-sm">Total Case Detected</p></div>*/}
            <div className="section-margin">
                <ChartContainer config={chartConfig} className="aspect-auto h-[421px] w-full">
                    <BarChart accessibilityLayer data={chartData}>
                        <CartesianGrid vertical={false}/>
                        <YAxis
                            tickLine={false}
                            axisLine={true}
                            tickFormatter={(value) => {
                                let formatter = Intl.NumberFormat('en', {notation: 'compact'});
                                return formatter.format(value)
                            }}
                        />
                        <XAxis
                            dataKey="type"
                            tickLine={false}
                            tickMargin={10}
                            axisLine={false}
                            label={""}

                        />
                        <ChartTooltip content={<ChartTooltipContent hideLabel/>}/>
                        <ChartLegend content={<ChartLegendContent/>}/>
                        <Bar
                            dataKey="data"
                            radius={4}
                            barSize={20}
                        />
                    </BarChart>
                </ChartContainer>
            </div>
        </div>
    )
}
