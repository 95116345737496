import profileImage from "../../Assets/image/background/dashboard.jpg";
import { Button } from "../ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import React from "react";
import { useNavigate } from "react-router-dom";

function Dashboard1() {
  const Navigate = useNavigate();

  const handleNavigate = () => {
    Navigate("/user_information");
  };

  const username = "Durgesh Pathak";
  const sectorData = [
    {
      name: "Union Government Agencies",
      alias: "union government agencies",
    },
    {
      name: "State Government Agencies",
      alias: "state government agencies",
    },
    {
      name: "Legal Metrology Officials in the State",
      alias: "lm",
    },
    {
      name: "Consumer",
      alias: "consumer",
    },
    {
      name: "Repairer",
      alias: "repairer",
    },
    {
      name: "Dealer",
      alias: "dealer",
    },
    {
      name: "Trader",
      alias: "trader",
    },
    {
      name: "Manufacturer",
      alias: "manufacturer",
    },
    {
      name: "Importer",
      alias: "importer",
    },
    {
      name: "Business Person",
      alias: "business person",
    },
  ];

  return (
    <main>
      <div className="flex flex-col items-center justify-center">
        <img
          className=""
          src={profileImage}
          alt={"test"}
          width={345}
          height={345}
        />
        <h2 className="font-mono">Hello {username}!</h2>
        <span className="text-paragraph text-center mt-4">
          Please complete your profile to get your complete dashboard
        </span>

        <div className="flex flex-col justify-center items-center mt-3 w-full mx-2 ">
          <div className="w-2/5 ml-2 mr-2">
            <label className="font-bold text-sm">Identity/ Role?</label>
            <div className="flex flex-col mt-1 md:flex-row gap-1 md:gap-3 w-full">
              <Select>
                <SelectTrigger className="rounded-l bg-white">
                  <SelectValue placeholder="Select" />
                </SelectTrigger>
                <SelectContent>
                  {sectorData.map((item, index) => (
                    <SelectItem value={item.alias} key={index}>
                      {item.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Button
                className="rounded-l px-8 font-bold md:w-1/3"
                onClick={handleNavigate}
              >
                NEXT
              </Button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Dashboard1;
