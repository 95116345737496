import { IoMdHelpCircle } from "react-icons/io";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "../../ui/tooltip"
export default function HelpForm({helpText}:{helpText:string}){
    return (
        <TooltipProvider delayDuration={0} >
            <Tooltip>
                <TooltipTrigger><IoMdHelpCircle  size={20} className="hover:text-indigo-500"/></TooltipTrigger>
                <TooltipContent>
                    <p>{helpText}</p>
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    )
}