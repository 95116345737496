import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Label, LabelList, Pie, PieChart, PieLabel } from "recharts";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../ui/chart";
import { cn } from "../../../lib/utils";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import {GetPieChartData, TChartData, TPieChartData} from "./chart.services";



const chartConfig = {
  approval: {
    label: "Approvals",
  },
  licence: {
    label: "Licence",
    color: "hsl(38, 98%, 50%,90%)",
  },
  registration: {
    label: "Registration",
    color: "hsl(38, 98%, 50%,70%)",
  },
  verification: {
    label: "Verification",
    color: "hsl(38, 98%, 50%,80%)",
  },
  package_commodity:{
    label:"Package Commidity",
    color: "hsl(38, 98%, 50%,60%)",
  },
  nomination:{
    label:"Nomination",
    color: "hsl(38, 98%, 50%,50%)",
  }
} satisfies ChartConfig;
export default function SectorApprovalCharts() {

  const [chartData, setPieChartData] = useState<any>([])
  const [selectedDate,setSelectedDate] =useState<string>("2023");

  const getStartDateAndEndDate = (dateString:string):{start_date:string,end_date:string}=>{
      const startDate:string = `${dateString.trim()}-01-01`

       const endDate:string = `${dateString.trim()}-12-31`

     return {
      start_date:startDate,
      end_date:endDate
     }
      
  }

  useEffect(()=>{
    const date = getStartDateAndEndDate(selectedDate)
    GetPieChartData(date.start_date,date.end_date).then(res=>{
       const total =  res?.map(x=>x?.approval)?.reduce((total:number,current:number)=>total+current);
       const modifyData = res?.map(data=> {
            const approval = +((data.approval/total)*100).toFixed(2)
           return {
               ...data,
               approval: approval
           }
       })
      setPieChartData(modifyData )
    })
  },[selectedDate])


  const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}:any) => {
      const RADIAN = Math.PI / 180;
      const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
      if(percent<0.14 && percent > 0.1){
          return (
              <text x={x} y={y} fill="black" textAnchor={percent > 0.14 ? "middle" : (x > cx ? "start" : "end")}
                    dominantBaseline="central">
                  {`${chartData[index].data} ${(percent * 100).toFixed(2)}%`}
              </text>
          );
      }

  };

  const {t, i18n} = useTranslation('home');

  const [language, setLanguage] = useState("en");

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <div className="bg-white px-8 py-4 rounded-xl shadow-lg">
      <div className="flex flex-row justify-between gap-4 items-center my-3">
        <h4>{t('categoriesWiseApprovals')}</h4>
        <div className={cn("flex flex-row items-center gap-4")}>
          <Select value={selectedDate} onValueChange={setSelectedDate}>
            <SelectTrigger className="w-[157px] rounded-3xl">
              <SelectValue placeholder="Select Year" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={"2023"} key={1}>
                2023-24
              </SelectItem>
              <SelectItem value={"2022"} key={2}>
                2022-23
              </SelectItem>
              <SelectItem value={"2021"} key={3}>
                2021-22
              </SelectItem>
              <SelectItem value={"2020"} key={4}>
                2020-21
              </SelectItem>
              <SelectItem value={"2019"} key={5}>
                2019-20
              </SelectItem>
              <SelectItem value={"2018"} key={6}>
                2018-19
              </SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      <ChartContainer
        config={chartConfig}
        className="mx-auto aspect-square max-h-[420px]"
      >
        <PieChart>
          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent className="bg-white" hideLabel />}
          />
          <Pie
            data={chartData}
            dataKey="approval"
            nameKey="data"
            innerRadius={50}
            startAngle={360 + 90}
            endAngle={90}
            paddingAngle={1}
            label={renderCustomizedLabel}
            labelLine={false}
          >
            `
            <LabelList
              dataKey="data"
              className="fill-black"
              stroke="none"
              fontSize={12}
              formatter={(value: keyof typeof chartConfig) => {
                const item = chartData.find(
                  (x: any) => x.data === value
                ) as any;

                if (item && item.approval > 14) {
                  return chartConfig[value]?.label + " " + item.approval + "%";
                } else {
                  return "";
                }
              }}
            />
          </Pie>
        </PieChart>
      </ChartContainer>
    </div>
  );
}
