import React from 'react'
import ActsAndRulesComponent from '../Components/ActAndRules/ActsAndRulesComponent'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'

const ActAndRulesPage = () => {
  return (
    <>
     <Navbar/>
      <ActsAndRulesComponent/>
      <Footer />
    </>
  )
}

export default ActAndRulesPage