import { useState , useEffect } from "react";
import { cn } from "../../lib/utils";
import { useTranslation } from 'react-i18next';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../ui/carousel";
import icon1 from "../../Assets/image/icons/clms-document.svg";
import icon2 from "../../Assets/image/icons/clms-login.svg";
import icon3 from "../../Assets/image/icons/clms-id.svg";
import icon4 from "../../Assets/image/icons/clms-desktop.svg";
import bg1 from "../../Assets/image/expandsection/1.jpg";
import bg2 from "../../Assets/image/background/maskgroup5.jpg";
import bg3 from "../../Assets/image/expandsection/3.jpg";
import bg4 from "../../Assets/image/expandsection/4.jpg";
import arrow from "../../Assets/image/icons/clms-arrow.svg";
import arrow2 from "../../Assets/image/background/maskgroup5.jpg";




export default function ClmsWorksComponent() {

  const {t, i18n} = useTranslation('home');

  const [language, setLanguage] = useState("en");

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const [hoverCard, setHoverCard] = useState(0);
  const data = [
    {
      name: "registration",
      description: "registrationDescription",
      icon: icon1,
      bgImage: bg1,
    },
    {
      name: "login",
      description: "loginDescription",
      icon:  icon2,
      bgImage: bg2,
    },
    {
      name: "makeProfile",
      description: "makeProfileDescription",
      icon: icon3,
      bgImage: bg3,
    },
    {
      name: "apply",
      description: "applyDescription",
      icon:  icon4,
      bgImage: bg4,
    },
  ];

  const conditionForCardOpen = (cardNumber: number) => {
    if (cardNumber == 1 && hoverCard == 1) {
      return true;
    }
    if (cardNumber == 2 && hoverCard == 2) {
      return true;
    }
    if (cardNumber == 3 && hoverCard == 3) {
      return true;
    }
    if (cardNumber == 4 && hoverCard == 4) {
      return true;
    }
    return false;
  };

  const conditionForCardClose = (cardNumber: number) => {
    if (cardNumber == 2 && hoverCard == 1) {
      return true;
    }
    if (cardNumber == 3 && hoverCard == 2) {
      return true;
    }
    if (cardNumber == 2 && hoverCard == 3) {
      return true;
    }
    if (cardNumber == 3 && hoverCard == 4) {
      return true;
    }

    return false;
  };

  return (
    <section id="clms" className="p-0 relative">
      <h1 className="absolute text-white z-[2] lg:top-12 md:top-8  top-6 left-1/2 -translate-x-1/2 w-full text-center">
        <span className="text-theme_yellow">{t('emaap')}</span> {t('clmsHeading')}
      </h1>
      <div className="lg:flex hidden">
        {data.map((item, index) => (
          <div
            className={cn(
              "overflow-hidden w-[25dvw] transition-all ease-in-out delay-300 shadow-lg",
              conditionForCardOpen(index + 1) ? "w-[50dvw]" : "",
              conditionForCardClose(index + 1) ? "w-[0]" : ""
            )}
            key={index + 1}
            onMouseEnter={() => setHoverCard(index + 1)}
            onMouseLeave={() => setHoverCard(0)}
          >
            <div className="h-[719px] w-full bg-gray-600 relative">
              <div className="h-[719px] w-[50vw] absolute top-0 left-0">
                <img
                  src={item.bgImage}
                  alt="Image 1"
                  className=" w-full h-full object-cover object-left-top "
                  width={717}
                  height={791}
                />
              </div>
              <div className="absolute bottom-0 left-0  px-8 pb-12 w-full">
                <div
                  className={cn(
                    "w-full flex justify-end mb-4 transition-all ease-in-out delay-300",
                    hoverCard == index + 1 ? "hidden" : ""
                  )}
                >
                  <img
                    src={arrow}
                    alt="test"
                    width={85}
                    height={85}
                    className="bottom-[30%] right-[5%]"
                  />
                </div>

                <div className="flex  h-[9rem] relative gap-1 ">
                  <div className="bg-gray-800 relative w-[1.55rem] flex-none">
                    <h5
                      className=" uppercase text-white absolute bottom-2 translate-y-full left-0
                     typo-paragraph1 transform -rotate-90 origin-top-left whitespace-nowrap cursor-vertical-text"
                    >
                      {`${index + 1}. ${t(item.name)}`}
                    </h5>
                  </div>

                  <div className="flex flex-col justify-end bg-theme_yellow w-full pb-6 px-4">
                    <div>
                      <img
                        src={item.icon}
                        alt="test"
                        width={30}
                        height={30}
                        className="mb-2"
                      />
                    </div>
                    <p className="typo-paragraph2 text-white ">
                      {t(item.description)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="lg:hidden">
        <Carousel>
          <CarouselContent>
            {data.map((item, index) => (
              <CarouselItem className={cn("overflow-hidden")} key={index + 1}>
                <div className="h-[60svh] sm:h-[75svh] md:h-[80svh] w-full bg-gray-600 relative">
                  <div className="h-full w-full absolute top-0 left-0">
                    <img
                      src={item.bgImage}
                      alt="Image 1"
                      className=" w-full h-full object-cover object-left-top "
                      width={717}
                      height={791}
                    />
                  </div>
                  <div className="absolute bottom-0 left-0  px-8 pb-12 w-full">
                    {/*<div*/}
                    {/*    className={cn("w-full flex justify-end mb-4 transition-all ease-in-out delay-300", (hoverCard == index + 1) ? "hidden" : "")}>*/}
                    {/*  <Image*/}
                    {/*      src="/image/icons/clms-arrow.svg"*/}
                    {/*      alt="test"*/}
                    {/*      width={85}*/}
                    {/*      height={85}*/}
                    {/*      className="bottom-[30%] right-[5%]"*/}
                    {/*  />*/}
                    {/*</div>*/}

                    <div className="flex  h-[9rem] relative gap-1 ">
                      <div className="bg-gray-800 relative w-[1.55rem] flex-none">
                        <h5
                          className=" uppercase text-white absolute bottom-2 translate-y-full left-0
                     typo-paragraph1 transform -rotate-90 origin-top-left whitespace-nowrap cursor-vertical-text"
                        >
                          {`${index + 1}. ${t(item.name)}`}
                        </h5>
                      </div>

                      <div className="flex flex-col justify-end bg-theme_yellow w-full pb-6 px-4">
                        <div>
                          <img
                            src={item.icon}
                            alt="test"
                            width={30}
                            height={30}
                            className="mb-2"
                          />
                        </div>
                        <p className="typo-paragraph2 text-white ">
                          {t(item.description)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious />
          <CarouselNext />
        </Carousel>
      </div>
    </section>
  );
}
