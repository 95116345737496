import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import icon1 from "../../Assets/image/icons/legislation.svg";
import icon2 from "../../Assets/image/icons/rules-acts.svg";
import icon3 from "../../Assets/image/icons/functions-lm.svg";
import icon4 from "../../Assets/image/icons/subordinates-offices.svg";
import overview from "../../Assets/image/background/overview.png"

export default function OverviewComponent() {
  const overviewData = [
    {
      name: "legislation",
      description: "legislationDescription",
      icon: icon1,
    },
    {
      name: "rules",
      description: "rulesDescription",
      icon: icon2,
    },
    {
      name: "functions",
      description: "functionsDescription",
      icon: icon3,
    },
    {
      name: "subordinateOffices",
      description: "subordinateOfficesDescription",
      icon: icon4,
    },
  ];

  const {t, i18n} = useTranslation('home');

  const [language, setLanguage] = useState("en");

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <section id="about_section" className="bg-white lg:bg-gradient-to-r from-theme_bg from-25% to-white to-25% section-padding">
      <div className="container">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 lg:gap-4 ">
          <div className="w-full h-auto  lg:block">
            <img
              className="w-full h-auto max-h-[400px] lg:max-h-[617px] object-contain"
              src={overview}
              width={629}
              height={617}
              alt={"overview"}
            />
          </div>
          <div>
            <h1 className="my-3">{t('overview')}</h1>
            <p className="text-paragraph">
              {t('paragraph')}
              {/* Explore a <strong>Centralized Legal Metrology System</strong>{" "}
              where regulatory oversight and measurement standards converge on a
              unified platform. This system simplifies certification,
              verification, and compliance across sectors, ensuring consistent,
              accurate, and transparent weights and measures for commercial
              transactions.The platform enhances efficiency in monitoring and
              enforcement, bolstering consumer protection and fairness in the
              marketplace */}
            </p>

            <div className="flex flex-col gap-2 md:gap-4 my-3">
              {overviewData.map((item, index) => (
                <div className="flex flex-row gap-2" key={index}>
                  <div className="pt-1 min-w-[24px] md:min-w-[48px] lg:min-w-[58px] aspect-square flex-none">
                    <img
                      className="lg:w-[58px] md:w-[48px] w-[24px]"
                      src={item.icon}
                      width={58}
                      height={58}
                      alt={"Legislation"}
                    />
                  </div>
                  <div>
                    <h4>{t(item.name)}</h4>
                    <p className="text-paragraph">{t(item.description)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
