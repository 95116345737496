import React from 'react'
import Header from '../Header/Header'
import Sidebar from '../Header/Sidebar'

function UserDashboard() {
  return (
    <>
    <div className='flex flex-row gap-8'>
      <Sidebar />
      <div className="mt-32">
        <h1>hello world</h1>
      </div>
    </div>
    </>
  )
}

export default UserDashboard
