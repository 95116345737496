import React, { useEffect, useId, useState } from "react";
import  RequiredTemplate  from "../required.form";
import { cn } from "../../../../lib/utils";

import "./select2.scss";
import Select from "react-select";
import HelpForm from "../help.form";
export interface MultiSelectOption {
  label: string;
  value: string;
}

type DropDownSelect2 = {
  className?: string;
  name: string;
  label?: string;
  isRequired?: boolean;
  dataOptions: MultiSelectOption[];
  selectedData?: string | null;
  onDataChange: (value: string | undefined) => void;
  isDisable?: boolean;
  helpText?: string;
};

export const CreateOptionMultiSelectOptionOnlyLabel = (
  label: string
): MultiSelectOption => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ""),
});

export const CreateOptionMultiSelectOptionOnlyValue = (
  label: string
): MultiSelectOption => ({
  label,
  value: label,
});

export const CreateOptionMultiSelectOption = (
  label: string,
  value: string
): MultiSelectOption => ({
  label,
  value,
});

export default function DropdownSelect2SingleSelect(props: DropDownSelect2) {
  const theme_color = "#FFAA17";

  const [multiOption, setMultiOption] = useState<MultiSelectOption[]>([]);
  const [selectValue, setSelectValue] = useState<MultiSelectOption | null>();

  const handleSelectData = () => {
    if (
      props.dataOptions &&
      props.selectedData &&
      props.selectedData.trim() != "" &&
      props.selectedData != null
    ) {
      const data = props.dataOptions.find(
        (x) => x.value === props.selectedData
      );
      if (data) {
        setSelectValue(data);
      } else {
        setSelectValue(null);
      }
    } else {
      setSelectValue(null);
    }
  };

  // useEffect(() => {
  //     setMultiOption(props.dataOptions)
  // }, [props.dataOptions]);

  useEffect(() => {
    setMultiOption(props.dataOptions);
    handleSelectData();
  }, [props.selectedData, props.dataOptions]);
  const handleMultiSelectChange = (values: MultiSelectOption | null) => {
    if (values) {
      props.onDataChange(values.value);
    } else {
      props.onDataChange("");
    }
  };

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      color: "transparent",
      background: "transparent", // Set the background color to transparent
      borderColor: state.isFocused ? theme_color : provided.borderColor, // Customize border color
      boxShadow: state.isFocused
        ? `0 0 0 1px ${theme_color}`
        : provided.boxShadow, // Customize box shadow
    }),
  };
  return (
    <>
      <div className={cn("grid w-full items-center gap-1.5", props.className)}>
        {props.label && (
          <div className="flex flex-row items-center gap-2 ">
            <label htmlFor={props.name} className="font-bold">
              {props.label}
               <RequiredTemplate
                required={props.isRequired ? props.isRequired : false}
              />
            </label>
            {props.helpText && <HelpForm helpText={props.helpText} />}
          </div>
        )}
        <Select
          instanceId={useId()}
          className="react-select-container"
          classNamePrefix="react-select"
          required={props.isRequired}
          isClearable
          options={multiOption}
          value={selectValue}
          onChange={(newValue) => handleMultiSelectChange(newValue)}
          styles={customStyles}
          isDisabled={props.isDisable}
        />
      </div>
    </>
  );
}
