import {Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis} from "recharts";

import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../ui/chart";

let chartData = [
  // { month: "Maharashtra", recovery: 28475500 },
  { month: "Uttar Pradesh", recovery: 33475500 },
  // { month: "Andhra Pradesh", recovery: 22759545  },
  // { month: "Jharkhand", recovery: 12465748 },
  // { month: "Karnataka", recovery: 27845362  },
];
chartData = chartData.map((x, index) => {
  const fill = index % 2 == 0 ? "#222429" : "#FFAA17";
  return {
    ...x,
    fill: fill,
  };
});
const chartConfig = {
  recovery: {
    label: "Fine Recovery",
    color: "hsl(var(--chart-1))",
  },
} satisfies ChartConfig;

export default function RecoveryGraphComponent() {
  return (
    <div className="bg-white theme-box-shadow rounded-lg p-4 w-full xl:max-w-[40%]">
      <div className="flex ">
        <h4>State-Wise Fine Recovery</h4>
      </div>
      <div className="section-margin">
        <ChartContainer
          config={chartConfig}
          className="aspect-auto w-full h-[327px]"
        >
          <BarChart
            accessibilityLayer
            data={chartData}
            layout="vertical"
            margin={{
              // left: -20,
              left: 40,
              bottom: 0,
              top: 0,
              right: 0,
            }}
            barSize={10}
          >
            <XAxis type="number" dataKey="recovery" hide />
            <YAxis
              dataKey="month"
              type="category"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              fontSize={14}
              tickFormatter={(value) => value}
            />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel className="bg-white"  />}
            />
            <Bar dataKey="recovery" radius={2} >
              {/*<LabelList*/}
              {/*    dataKey="recovery"*/}
              {/*    position="right"*/}
              {/*    offset={8}*/}
              {/*    className="fill-black"*/}
              {/*    fontSize={12}*/}
              {/*/>*/}
            </Bar>
          </BarChart>
        </ChartContainer>
      </div>
    </div>
  );
}
