import React from "react";
import Header from "../Components/Header/Header";
import NewLicenseComponent from "../Components/NewLicenseComponent/NewLicenseComponent";

const NewLicensePage = () => {
  return (
    <div>
      <Header />
      <NewLicenseComponent />
    </div>
  );
};

export default NewLicensePage;
