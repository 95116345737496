import React from 'react'
import { useState, useEffect } from 'react';
import PageHeaderComponent from '../Helper/pageHeader/pageHeader.component'
import JudgementBackground from "../../Assets/image/background/judgements2.png"
import { IoSearchOutline, IoSearchSharp } from "react-icons/io5";
import { TJudgementsData, JudgementsData } from './judgements.services';
import IconPdf from "../../Assets/image/icons/pdf.svg"
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
  } from "../ui/accordion";


function JugementsComponent() {

    const [search, setSearch] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [judgementsData,setJudgementsData] = useState<TJudgementsData[]>(JudgementsData);
    const [judgementsCategory,setJudgementsCategory] = useState<string[]>([]);

    useEffect(()=>{
        const data = Array.from(new Set([...judgementsData.map(x=>x.category)]))
        setJudgementsCategory(data)
    },[judgementsData])

    const handleSearchChange = (e:any) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    useEffect(()=>{
        if(searchQuery=="" || searchQuery==" "){
            setJudgementsData(JudgementsData);
        }else{
           setJudgementsData(JudgementsData.filter(x=>x.name.toLowerCase().includes(searchQuery.toLowerCase()))); 
        }
    }, [searchQuery])

  return (
    <>
    <PageHeaderComponent imageUrl={JudgementBackground}>
        Judgements
    </PageHeaderComponent>

    <div className="text-white flex flex-col items-center">
        <div className="bg-white w-full text-black flex flex-col justify-between px-4 sm:px-14">
            {/* upper */}
            <div className="py-4 mt-4 flex justify-between items-center">
                <div className="flex space-x-2">
                <h3>
                Court Cases &{" "}
                <span className="text-amber-500">Judgements</span>
              </h3>
                </div>
                <div className="hidden flex-row border border-gray-300 rounded-full p-2 md:flex md:w-[300px] lg:w-[404px]">
                <IoSearchOutline className="text-2xl mr-2 text-amber-500" />
                <input
                className="outline-none flex-1 text-sm placeholder-gray-500"
                type="text"
                placeholder="Search your keyword"
                value={searchQuery}
                onChange={handleSearchChange}
                />
                </div>

                {/* small screen search bar */}
                <div
              className="flex flex-row md:hidden cursor-pointer border border-gray-300 rounded-full p-2"
              // onClick={() => setSearch((prev) => !prev)}
                onClick={() => setSearch((prev) => !prev)}

            >
              <IoSearchSharp
                className="text-2xl text-amber-500"
                // onClick={() => setSearch((prev) => !prev)}
              />
              {/*<span className="text-gray-500">Search</span>*/}
            </div>
            </div>

            {search && (
            <input
              className="flex md:hidden sm:ml-[50%] outline-none flex-1 text-sm placeholder-gray-500 border border-gray-300 rounded-full p-2"
              type="text"
              placeholder="Search your keyword"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            )}


            {/* lower heading*/}
            <div className="py-4">
                {/* Accordion use here  */}
                <Accordion type="multiple" defaultValue={["item-0"]}>
                {judgementsCategory.map((rule, ruleIndex)=>(
                    <AccordionItem key={ruleIndex} value={`item-${ruleIndex}`}>
                        <AccordionTrigger className="text-lg bg-black_bg text-white p-2 py-3">
                        <div className="mx-auto">{rule}</div>
                        </AccordionTrigger>
                        <AccordionContent>
                            {judgementsData.filter(x=>x.category.trim().toLowerCase()==rule.trim().toLowerCase()).map((item, itemIndex) => (
                                <div key={itemIndex}
                                className="shadow-sm border my-2 text-light_gray font-light text-sm flex justify-between items-center"
                              >
                                <p className="px-3">{item.name}</p>
                        <a href={item.link} target="_blank">
                        <div className="bg-pdf_green p-1 px-3 flex flex-col items-center cursor-pointer">
                          <img
                            src={IconPdf}
                            alt="test"
                            width={24}
                            height={24}
                          />
                          <span className="text-xs font-semibold text-white">
                            view
                          </span>
                        </div>
                        </a>
                              </div>
                            ))}
                        </AccordionContent>
                    </AccordionItem>
                ))}
                </Accordion>
            </div>
        </div>
    </div>


    </>
  )
}

export default JugementsComponent
