import { useNavigate } from "react-router-dom";
import RequiredTemplate from "../Helper/form/required.form";
import { Button } from "../ui/button";
import { LoginComponent } from "./login.component";
import React from "react";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";

type fields = {
  label: string;
  otp: string[];
  setOtp: React.Dispatch<React.SetStateAction<string[]>>;
  inputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>;
  handleChange: any;
  handleKeyDown: any;
};

function OtpInput({
  label,
  otp,
  setOtp,
  inputRefs,
  handleChange,
  handleKeyDown,
}: fields) {
  return (
    <div className="flex flex-col gap-2">
      <label className="font-semibold">
        {label}
        <RequiredTemplate required={false} />
      </label>
      <div className="grid grid-cols-6 gap-4">
        {otp.map((item: string, i: number) => (
          <input
            type="number"
            ref={(ele) => {
              inputRefs.current[i] = ele;
            }}
            onFocus={(e) => e.target.select()}
            onChange={(e) => handleChange(e, i, otp, setOtp)}
            onKeyDown={(e) => handleKeyDown(e, i, otp, inputRefs)}
            key={i}
            className="h-[3rem] w-[3rem] rounded-md p-4 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none border-2"
            value={item}
            maxLength={1}
          />
        ))}
      </div>
      <div className="flex justify-end mr-2 items-center w-full">
        <span>Resend OTP</span>
      </div>
    </div>
  );
}

function EnterRegisterOTP() {
  const Navigate = useNavigate()
  const [mobileOtp, setMobileOtp] = useState(new Array(6).fill(""));
  const [emailOtp, setEmailOtp] = useState(new Array(6).fill(""));

  const mobileInputRefs = useRef<(HTMLInputElement | null)[]>(
    new Array(6).fill(null)
  );
  const emailInputRefs = useRef<(HTMLInputElement | null)[]>(
    new Array(6).fill(null)
  );

  const handleChange = (
    e: any,
    i: number,
    otp: string[],
    setOtp: React.Dispatch<React.SetStateAction<string[]>>,
    inputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>
  ) => {
    let value = e.target.value;
    const newOtp = [...otp];
    newOtp[i] = value;
    setOtp(newOtp);

    if (value !== "" && i < otp.length - 1) {
      inputRefs.current[i + 1]?.focus();
    }
  };

  const handleKeyDown = (
    e: any,
    i: number,
    otp: string[],
    inputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>
  ) => {
    if (e.key === "Backspace" && !otp[i] && i > 0) {
      inputRefs.current[i - 1]?.focus();
    }
  };
 
  const handleSubmit = ((e:any)=>{
    e.preventDefault();
    let optString= mobileOtp.join("");
    let optEmailString= emailOtp.join("");

    if (optString === "123456" && optEmailString === "123456" ) {

     
        Navigate("/create_password")
      
    } else {
      // Show failure message
      Swal.fire({
        title: "Something went wrong!",
        icon: "error",
        confirmButtonText: "Okay",
        customClass: {
          confirmButton: "swal-custom-btn",
          title: "swal-custom-title",
          popup: "swal-custom-popup",
        },
      });
    }
  })

  return (
    <>
      <LoginComponent>
        <form className="flex flex-col gap-3 mt-4" onSubmit={handleSubmit}>
          <p className="tracking-tight text-sm mb-3 text-center">
            Enter six digit OTP we've sent on your mobile<br></br> number
            "XXXXXXX897" and email id "abcXXXXXXXXmail.com".
          </p>

          <OtpInput
            label="Enter Mobile Otp"
            otp={mobileOtp}
            setOtp={setMobileOtp}
            inputRefs={mobileInputRefs}
            handleChange={(e: any, i: number) =>
              handleChange(e, i, mobileOtp, setMobileOtp, mobileInputRefs)
            }
            handleKeyDown={(e: any, i: number) =>
              handleKeyDown(e, i, mobileOtp, mobileInputRefs)
            }
          />

          <OtpInput
            label="Enter Email Otp"
            otp={emailOtp}
            setOtp={setEmailOtp}
            inputRefs={emailInputRefs}
            handleChange={(e: any, i: number) =>
              handleChange(e, i, emailOtp, setEmailOtp, emailInputRefs)
            }
            handleKeyDown={(e: any, i: number) =>
              handleKeyDown(e, i, emailOtp, emailInputRefs)
            }
          />

          <Button
            className="rounded-md uppercase border-none font-bold w-full h-12 mt-6"
            size="long"
            variant="default"
          >
            Next
          </Button>
        </form>
      </LoginComponent>
    </>
  );
}

export default EnterRegisterOTP;
