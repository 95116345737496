import React from "react";
const RequiredTemplate = ({ required }: { required?: boolean }) => {
  if (required) {
    return (
      <span className="ps-1" style={{ color: "red" }}>
        *
      </span>
    );
  } else {
    return null;
  }
};
export default RequiredTemplate;
