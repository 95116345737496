import CaseGraphComponents from "./CaseGraphComponent";
import RecoveryGraphComponent from "./RecoveryGraphComponent";
import CaseStaticsComponent from "./casesStatics/caseStatics.component";
import React from "react";
import DropdownSelect2SingleSelect from "../Helper/form/select2/select.dropdown";





export default function EnforcementMain() {
    const StatisticsCards = [
        {
            icon: "/logo/inspection.svg",
            data: "640,253",
            title: "Inspection",
            startColor: "#48BCF8",
            endColor: "#4394EF",
            bgUrl: "/background/circle/bg1.png"
        },
        {
            icon: "/logo/detected.svg",
            data: "40,085",
            title: "Cases Detected",
            startColor: "#6457FE",
            endColor: "#4C3DDB",
            bgUrl: "/background/circle/bg2.png"
        },
        {
            icon: "/logo/mitigated.svg",
            data: "27,312",
            title: "Cases Mitigated",
            startColor: "#AE4BFD",
            endColor: "#9109FE",
            bgUrl: "/background/circle/bg3.png"
        },
        {
            icon: "/logo/amount.svg",
            data: "33,475,500",
            title: "Amount Recovered",
            startColor: "#19C5A3",
            endColor: "#009F80",
            bgUrl: "/background/circle/bg4.png"
        },
    ];

    return (
        <>
            <section className=" bg-theme_bg">
                <div className="container section-padding">
                    <div className="flex flex-col md:flex-row w-full gap-4 md:items-center">
                        <h3 className="flex-none">Overall Enforcement Statistics</h3>
                        <div className="flex md:flex-row-reverse w-full">
                        {/*  <DropdownSelect2SingleSelect*/}
                        {/*      name={"type"}*/}
                        {/*      dataOptions={[*/}
                        {/*        {*/}
                        {/*          label: "Central",*/}
                        {/*          value: "central",*/}
                        {/*        },*/}
                        {/*      ]}*/}
                        {/*      onDataChange={(value: any) => {*/}
                        {/*      }}*/}
                        {/*  />*/}
                            <div>
                                <DropdownSelect2SingleSelect
                                    className="bg-white"
                                    name={"type"}
                                    dataOptions={[
                                        {
                                            label: "2022-2023",
                                            value: "2022-2023",
                                        },
                                    ]}
                                    onDataChange={(value: any) => {
                                    }}
                                    selectedData={"2022-2023"}
                                />
                            </div>


                        {/*  <SingleDatePicker name={"to"} dataChange={() => {*/}
                        {/*  }}/>*/}
                        {/*  <SingleDatePicker name={"from"} dataChange={() => {*/}
                        {/*  }}/>*/}
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4 mt-5">
                        {StatisticsCards.map((item, index) => (
                            <div key={index} className="rounded-lg card-gradient py-6 px-8 relative card-isolate"
                                 style={{
                                     "--start-color": item.startColor,
                                     "--end-color":item.endColor,
                                 } as React.CSSProperties}>
                                <div className="absolute right-0 top-0 overflow-hidden w-full h-full z-[-1]"><img
                                    className="w-full h-full object-right object-contain" src={item.bgUrl}
                                    alt="cirlce abstract"/></div>
                                <div className="flex flex-col justify-start text-white gap-4">
                                    <div className="size-[32px]"><img className={"w-full h-full"} src={item.icon} alt={item.title}/></div>
                                    <h3>{item.data}</h3>
                                    <div>
                                        <small className=" py-1 px-4 bg-black/40 rounded-xl">
                                            {item.title}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className="bg-theme_bg">
                <div className="container section-padding !pb-4">
                    <div className="flex xl:flex-row gap-8 flex-col">
                        <CaseGraphComponents/>
                        <RecoveryGraphComponent/>
                    </div>
                </div>
            </section>

            <section className="bg-theme_bg">
                <div className="container section-padding !py-4">
                    <div className="grid grid-cols-1 w-full gap-4">
                        <CaseStaticsComponent/>
                    </div>
                </div>
            </section>
        </>
    );
}
