import React from 'react'
import ActiveLinkComponent from '../Helper/activeLink/activeLink.component'

function Sidebar() {
  return (
    <div className='flex-col items-start bg-[#222429] w-[264px] h-screen pl-6 text-white'>
      <div className='flex flex-col mt-24 gap-5 font-light'>
      <ActiveLinkComponent url='/user_dashboard'>
      <div><span>logo</span> Dashboard</div>
      </ActiveLinkComponent>
      <ActiveLinkComponent url='/new_licence'>New Licence</ActiveLinkComponent>
      <ActiveLinkComponent url='/new_licence'>Licence Renewal</ActiveLinkComponent>
      <ActiveLinkComponent url='/update_licence'>Update Licence</ActiveLinkComponent>
      <ActiveLinkComponent url='/11'>Reg. of packaged<br></br> commodities</ActiveLinkComponent>
      <ActiveLinkComponent url='/12'>Reg. of importers of<br></br> weights and measures</ActiveLinkComponent>
      <ActiveLinkComponent url='/13'>Model Approval system</ActiveLinkComponent>
      <ActiveLinkComponent url='/14'>Nomination of Director<br></br> of companies</ActiveLinkComponent>
      <ActiveLinkComponent url='/15'>Payment</ActiveLinkComponent>
      <ActiveLinkComponent url='/16'>History</ActiveLinkComponent>
      </div>
     
    </div>
  )
}

export default Sidebar
