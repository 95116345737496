import ReportBg from "../../Assets/image/background/reports-bg.jpg";
import SingleDatePicker from "../Helper/form/datepicker/single/single.datepicker";
import DropdownSelect2SingleSelect, {
  CreateOptionMultiSelectOptionOnlyLabel,
} from "../Helper/form/select2/select.dropdown";
import PageHeaderComponent from "../Helper/pageHeader/pageHeader.component";
import DataTables, { DataTableInfo } from "../datatables/DataTables";
import { Button } from "../ui/button";
import ReportsHook from "./reports.hook";

export default function ReportMainComponent() {
  const {
    serviceOptions,
    StatesData,
    PeriodData,
    DivisionData,
    services,
    setServices,
    months,
    setMonth,
    year,
    setYear,
    MonthData,
    period,
    setPeriod,
    setStates,
    states,
    showDataTable,
    handleSubmit,
    dataTableData,
    YearData,
    disableSubmitButton,
    dataTableInfo,
  } = ReportsHook();

  return (
    <>
      <PageHeaderComponent imageUrl={ReportBg}>Reports</PageHeaderComponent>
      <section className="container section-margin  pb-20">
        <div className="p-8 theme-box-shadow rounded-lg">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8 ">
            {/*<DropdownSelect2SingleSelect name={"service"} label={"Service"}*/}
            {/*                             selectedData={services}*/}
            {/*                             dataOptions={serviceOptions.map(x => CreateOptionMultiSelectOptionOnlyLabel(x))}*/}
            {/*                             onDataChange={(value) => {*/}
            {/*                                setServices(value)*/}
            {/*                             }}/>*/}
            <DropdownSelect2SingleSelect
              name={"state"}
              label={"State"}
              selectedData={states}
              dataOptions={StatesData()}
              onDataChange={(value) => setStates(value)}
            />
            {/*<DropdownSelect2SingleSelect name={"division"} label={"Division"}*/}
            {/*                             dataOptions={DivisionData.map(x => CreateOptionMultiSelectOptionOnlyLabel(x))}*/}
            {/*                             onDataChange={(value) => {*/}
            {/*                             }}/>*/}
            <DropdownSelect2SingleSelect
              name={"period"}
              label={"Period"}
              selectedData={period}
              dataOptions={PeriodData.map((x) =>
                CreateOptionMultiSelectOptionOnlyLabel(x)
              )}
              onDataChange={setPeriod}
            />
            {period && (period == "monthly" || period == "yearly") && (
              <DropdownSelect2SingleSelect
                name={"year"}
                label={"Year"}
                selectedData={year}
                dataOptions={YearData()}
                onDataChange={setYear}
              />
            )}

            {period && year && period == "monthly" && (
              <>
                <DropdownSelect2SingleSelect
                  name={"month"}
                  label={"Month"}
                  dataOptions={MonthData()}
                  selectedData={months}
                  onDataChange={setMonth}
                />
              </>
            )}

            {/*<SingleDatePicker label="From" name={"fromDate"} dataChange={(value) => {*/}
            {/*}}/>*/}
            {/*<SingleDatePicker label="To" name={"toDate"} dataChange={(value) => {*/}
            {/*}}/>*/}
          </div>
          <div className="flex justify-end mt-8">
            <Button
              onClick={(event) => {
                handleSubmit(event);
              }}
              size="long"
              disabled={disableSubmitButton()}
            >
              SUBMIT
            </Button>
          </div>
        </div>
      </section>
      {showDataTable && (
        <section className="container ">
          <div className="theme-box-shadow rounded-lg">
            <DataTables
               states={states}
              table_info={dataTableInfo}
              loading={false}
              data={dataTableData}
              groupBy={"applicationName"}
            />
          </div>
        </section>
      )}
    </>
  );
}
