import AxiosUtils from "../../Utils/axios.utils";

export type TLicence={
    id: string,
    companyName: string,
    certificateNumber: string,
    certificateLink:string
}


export const GetLicenceData = async (licenceNumber:string):Promise<TLicence|undefined>=>{
    try{
        const response  = await AxiosUtils.post("/api/licence",{licence_number:licenceNumber})
        if (response.status != 200) {
            throw new Error("error while fetch")
        }
        return response.data.data as TLicence
    }catch (e: any) {
        if(e.status && e.status == 404){
            alert("Licence Number Not Found In Our Records")
        }
        console.log(e)
        return undefined
    }
}