import React from 'react'
import { Link } from 'react-router-dom';
import { IoSearchOutline } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import logo from "../../Assets/image/logo/logon1.png"
import profileIcon from "../../Assets/image/icons/userprofile.svg"

function Header() {

    const username = "Durgesh Pathak"
  return (
    <header className=" shadow rounded-md my-2 mx-4 z-[10]">
      <nav className="flex flex-row justify-between w-full p-3 ">
        <div className="my-auto">
          <Link to={"/"}>
            <img
              src={logo}
              className="my-auto ml-6 object-center"
              alt={"logo"}
              width={121}
              height={39}
            />
          </Link>
        </div>
        <div className="gap-4 items-center lg:flex flex-row hidden">
          <div className="relative flex items-center justify-center border border-gray-300 rounded-full w-[350px] h-10">
            <IoSearchOutline
              className="absolute left-2 text-2xl mr-2 text-amber-500"
              size={20}
            />
            <input
              className="h-auto w-full outline-none ml-8 bg-transparent text-xs"
              type="text"
              placeholder="Search here"
            />
          </div>
          <div className=" flex flex-row items-center gap-2">
            <img
              src={profileIcon}
              alt="test"
              width={40}
              height={40}
            />
            <div className="flex flex-col items-end gap-1">
              <span className="text-sm font-semibold">{username}</span>
              <div className="">
                <Select defaultValue={"profile"}>
                  <SelectTrigger className="bg-transparent border-none font-medium text-xs p-2 h-4 text-gray-600 pr-0 ">
                    <SelectValue placeholder="Select Option" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="profile">Profile</SelectItem>
                    <SelectItem value="editprofile">Edit Profile</SelectItem>
                    <SelectItem value="logout">Logout</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row-reverse w-full lg:hidden items-center  pr-4 sm:pr-[2rem] sm:size-[20] md:size-[26] ">
            <GiHamburgerMenu size={26} />
        </div>
      </nav>
    </header>
  )
}

export default Header
