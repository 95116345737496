import AxiosUtils from "../../Utils/axios.utils";

export type TReportData = {
    applicationName: string
    serviceName: string
    notifiedTimeline: string
    maxDaysTaken: number
    minDaysTaken: number
    applicationReceived: number
    applicationApproved: number
}

export type TReportPayload = {
    start_date?: string,
    end_date?: string,
    state_lgd_code?: number
}
export const GetReportData = async (body: TReportPayload): Promise<TReportData[]> => {
    try {
        const response = await AxiosUtils.post("/api/reports", body)
        if (response.status != 200) {
            throw new Error("error while fetch")
        }
        return response.data.data as TReportData[]
    } catch (e) {
        console.log(e)
        return []
    }

}