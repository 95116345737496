import { Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import ActAndRulesPage from "./Pages/ActAndRulesPage";
import EnforcementPage from "./Pages/EnforcementPage";
import ReportsPage from "./Pages/ReportsPage";
import DashboardPage from "./Pages/DashboardPage";
import LicenceRegistrationComponent from "./Components/LicenseRegistrationComponent/LicenseRegistrationComponent";
import LicenceRegistrationPage from "./Pages/LicenseRegistrationPage";
import Login from "./Components/authentication/Login";
import Register from "./Components/authentication/Register";
import Otp from "./Components/authentication/Otp";
import Password from "./Components/authentication/Password";
import NewLicensePage from "./Pages/NewLicensePage";
import JudgementsPage from "./Pages/JudgementsPage";
import PageNotFound from "./Components/PageNotFound/PageNotFound";
import LoadingScreen from "./Components/loadingscreen/loading.component";
import LoginWithOTP from "./Components/authentication/LoginWithOTP";
import EnterLoginOTP from "./Components/authentication/EnterLoginOTP";
import EnterRegisterOTP from "./Components/authentication/EnterRegisterOTP";
import CreatePassword from "./Components/authentication/CreatePassword";
import UserDashboard from "./Components/Dashboard1/UserDashboard";

function App() {
  return (
    <>
      {/* all routes */}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/act_rules" element={<ActAndRulesPage />} />
        <Route path="/enforcements" element={<EnforcementPage />} />
        <Route path="/reports" element={<ReportsPage />} />
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route
          path="/user_information"
          element={<LicenceRegistrationPage />}
        />
        <Route path="/new_license" element={<NewLicensePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/otp" element={<Otp />} />
        {/* <Route path="/password" element={<Password />} /> */}
        <Route path="/judgements" element={<JudgementsPage />} />
        <Route path="/login_with_otp" element={<LoginWithOTP />} />
        <Route path="/enter_login_otp" element={<EnterLoginOTP />} />
        <Route path="/enter_register_otp" element={<EnterRegisterOTP />} />
        <Route path="/create_password" element={<CreatePassword />} />
        <Route path="/user_dashboard" element={<UserDashboard />} />
        <Route path="*" element={<PageNotFound />} />

      </Routes>
    </>
  );
}

export default App;
