import React, {ReactNode} from "react";

export default function PageHeaderComponent({imageUrl, children}:{imageUrl:string, children:ReactNode}){
    return (
        <div className="overflow-hidden relative">
            <img
                className="object-left-top object-cover w-full max-h-[150px] sm:max-h-[300px]  lg:max-h-[409px] 2xl:max-h-[500px]"
                src={imageUrl}
                alt={"heading-image"}
                width={1453}
                height={409}
            />
            <h1 className="top-1/2 right-[20%] -translate-y-1/2 absolute text-white">
                {children}
            </h1>
        </div>
    )
}