import React from 'react'
import ReportMainComponent from '../Components/ReportsComponent/ReportMainComponent'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'

const ReportsPage = () => {
  return (
    <div>
        <Navbar/>
        <ReportMainComponent/>
        <Footer/>
    </div>
  )
}

export default ReportsPage