import logo from "../../Assets/image/logo/logon1.png";
import ActiveLinkComponent from "../Helper/activeLink/activeLink.component";
import { Button } from "../ui/button";
import { Sheet, SheetContent, SheetTrigger } from "../ui/sheet";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

export default function Navbar() {
  const Navigate = useNavigate();
  const { t, i18n } = useTranslation("navbar");

  const [language, setLanguage] = useState("en");
  const [fontSize, setFontSize] = useState(16); // Default font size

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  const increaseFontSize = () => {
    setFontSize((prev) => {
      if (prev >= 21) {
        Swal.fire({
          title: "You have reached the Maximum font size!",
          icon: "warning",
          confirmButtonText: "Okay",
          customClass: {
            confirmButton: "swal-custom-btn",
            title: "swal-custom-title",
            popup: "swal-custom-popup",
          },
        });
        return prev;
      }
      return prev + 2;
    });
  };
  const decreaseFontSize = () => {
    setFontSize((prev) => {
      if (prev <= 11) {
        Swal.fire({
          title: "Minimum Font Size Reached!",
          text: "You have reached the minimum font size!.",
          icon: "warning",
          confirmButtonText: "Okay",
          customClass: {
            confirmButton: "swal-custom-btn",
          },
        });
        return prev;
      }
      return prev - 2;
    });
  };

  useEffect(() => {
    document.documentElement.style.setProperty("--font-size", `${fontSize}px`);
  }, [fontSize]);

  const handleNavigate = () => {
    Navigate("/login");
  };

  return (
    <header className="sticky z-[10] top-0 bg-[#F4F5F8]">
      <div className="lg:hidden text-center p-1 typo-paragraph2 font-bold space-y-1 md:flex md:flex-row justify-around">
        <h5>{t("gov_message")}</h5>
        <ul className="flex flex-row justify-center typo-paragraph2 font-bold gap-4">
          <li>A-</li>
          <li>A</li>
          <li>A+</li>
          <li>|</li>
          <li>
            <select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              className="bg-theme_bg"
            >
              <option value="en">ENG</option>
              <option value="hi">HIN</option>
            </select>
          </li>
        </ul>
      </div>
      <nav className="flex flex-row w-full shadow-lg">
        <div className="bg-white p-4  border-r-4 border-theme_yellow flex flex-col items-center min-w-[156px]">
          <div className="my-auto">
            <Link to="/">
              <img
                src={logo}
                className="my-auto object-center "
                alt={"logo"}
                width={210}
                height={90}
              />
            </Link>
          </div>
        </div>
        <div className="lg:flex flex-col w-full hidden">
          <div className="flex flex-row justify-between py-2 items-center pl-4 pr-4 sm:pr-[2rem] lg:pr-[4rem]  2xl:pr-[10rem] my-auto">
            <h5 className="typo-paragraph2 font-bold">{t("gov_message")}</h5>
            <div className="flex flex-row items-center gap-6">
              <h5 className="typo-paragraph2 font-bold bg-[#222429] text-white px-2 py-1 rounded-sm">
                {t("citizen_portal")}
              </h5>
              <ul className="flex flex-row typo-paragraph2 font-bold gap-4">
                <li onClick={decreaseFontSize} style={{ cursor: "pointer" }}>
                  A-
                </li>
                <li
                  onClick={() => setFontSize(16)}
                  style={{ cursor: "pointer" }}
                >
                  A
                </li>
                <li onClick={increaseFontSize} style={{ cursor: "pointer" }}>
                  A+
                </li>

                <li>|</li>
                <li>
                  <select
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                    className="bg-theme_bg"
                  >
                    <option value="en">ENG</option>
                    <option value="hi">HIN</option>
                  </select>
                </li>
              </ul>
            </div>
          </div>
          <div className=" bg-white py-4  pl-4 pr-4 sm:pr-[2rem] lg:pr-[4rem]  2xl:pr-[10rem] mt-auto">
            <div className="flex flex-row justify-between  items-center">
              <ul className="flex flex-row items-center gap-4 md:gap-8 lg:gap-14 typo-paragraph1">
                <li>
                  <ActiveLinkComponent url={"/"}>
                    {t("home")}
                  </ActiveLinkComponent>
                </li>
                <li>
                  <ActiveLinkComponent url={"/act_rules"}>
                    {t("act_rules")}
                  </ActiveLinkComponent>
                </li>
                <li>
                  <ActiveLinkComponent url={"/enforcements"}>
                    {t("enforcement_activity")}
                  </ActiveLinkComponent>
                </li>
                <li>
                  <ActiveLinkComponent url={"/judgements"}>
                    {t("judgements")}
                  </ActiveLinkComponent>
                </li>

                <li>
                  <ActiveLinkComponent url={"/reports"}>
                    {t("reports")}
                  </ActiveLinkComponent>
                </li>
              </ul>
              <div>
                <Button
                  className="rounded-none uppercase font-bold "
                  size="long"
                  variant="outline"
                  // disabled={true}
                  onClick={handleNavigate}
                >
                  {t("login")}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row-reverse w-full lg:hidden items-center bg-white pr-4 sm:pr-[2rem] gap-4">
          <Sheet>
            <SheetTrigger>
              <GiHamburgerMenu size={32} />
            </SheetTrigger>
            <SheetContent className="flex flex-col justify-between">
              <ul className="flex flex-col gap-6 mt-5">
                <li>
                  <ActiveLinkComponent url={"/"}>Home</ActiveLinkComponent>
                </li>
                <li>
                  <ActiveLinkComponent url={"/act_rules"}>
                    Act & Rules
                  </ActiveLinkComponent>
                </li>

                <li>
                  <ActiveLinkComponent url={"/judgements"}>
                    Judgements
                  </ActiveLinkComponent>
                </li>
                <li>
                  <ActiveLinkComponent url={"/enforcements"}>
                    Enforcements Activities
                  </ActiveLinkComponent>
                </li>
                <li>
                  <ActiveLinkComponent url={"/reports"}>
                    Reports
                  </ActiveLinkComponent>
                </li>
              </ul>
              <div>
                <Button
                  className="rounded-none uppercase font-bold "
                  size="long"
                  variant="outline"
                >
                  Login
                </Button>
              </div>
            </SheetContent>
          </Sheet>

          <h5 className="typo-paragraph2 font-bold bg-[#222429] text-white px-2 py-1 rounded-sm">
            {t("citizen_portal")}
          </h5>

          {/* <select value={language} onChange={(e) => setLanguage(e.target.value)} className="bg-white">
            <option value="en">ENG</option>
            <option value="hi">HIN</option>
          </select> */}
        </div>
      </nav>
    </header>
  );
}
