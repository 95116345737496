import AxiosUtils from "../../../Utils/axios.utils";

export type TChartData = {

    submitted: number
    approved: number
    rejected: number
    date: string
}

export const GetChartData = async(): Promise<TChartData[]> =>{
    try {
        const response = await AxiosUtils.get("/api/reports/lastSixMonthApproval")
        if (response.status != 200) {
            throw new Error("error while fetch")
        }
        return response.data.data as TChartData[]
        
    } catch (error) {
        return [];
    }

}

export type TPieChartData = {

    data: string
    approval: number
    fill: string
}

export const GetPieChartData = async(start_date:string, end_date:string): Promise<TPieChartData[]> =>{

    try {
        const response = await AxiosUtils.post("/api/reports/categoryWiseApprovalsChart", {
            start_date, 
            end_date
        })
        if (response.status !=200){
            throw new Error("error while fetch")
        }
        return response.data.data as TPieChartData[]

    } catch (error) {
        return[];
    }
}