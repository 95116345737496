import React from 'react'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
import JugementsComponent from '../Components/Judgements/JugementsComponent'

function JudgementsPage() {
  return (
    <>
    <Navbar/>
      <JugementsComponent/>
      <Footer />
    </>
  )
}

export default JudgementsPage
