import React, {useState} from "react";
import {FaFacebookSquare, FaLinkedin} from "react-icons/fa";
import {IoIosGlobe, IoIosSend} from "react-icons/io";
import {Carousel, CarouselApi, CarouselContent, CarouselItem} from "../ui/carousel";
import ActiveLinkComponent from "../Helper/activeLink/activeLink.component";

import bis from "../../Assets/image/footer/new/bis.svg";
import emblem from "../../Assets/image/footer/new/ashoka.svg";
import nth from "../../Assets/image/footer/new/nth.svg";
import jgj from "../../Assets/image/footer/new/jgj.svg";
import nccf from "../../Assets/image/footer/new/nccf.svg";


import footer1 from "../../Assets/image/footer/DOCA.svg";

import footer2 from "../../Assets/image/footer/NCDRC.svg";
import footer3 from "../../Assets/image/footer/NCH.svg";
import footer4 from "../../Assets/image/footer/image 7.svg";
import footer5 from "../../Assets/image/footer/BIS.svg";
import footer6 from "../../Assets/image/footer/NTH.svg";

import footer8 from "../../Assets/image/icons/Niclogo.png";
import footerBg from "../../Assets/image/background/footer-background.jpg";
import footerBot from "../../Assets/image/footer/bot.png";
import {cn} from "../../lib/utils";

import { useRef, useEffect } from 'react';
import AutoScroll from "embla-carousel-auto-scroll";
import { useLocation, useNavigate } from "react-router-dom";


const menuitem = [
    {title: "Home", link: "#landing_section",},
    {title: "About", link: "#about_section",},
    {title: "Graphs", link: "#graphs",},
    {title: "Latest News", link: "#stay_updated",},
    {title: "How it works", link: "#clms",},
];
const quicklinks = [
    {
        title: "Enforcement Activities",
        link: "/enforcements",
    },
    {title: "Judgements", link: "/judgements"},
    {title: "Legal Metrology Acts", link: "/act_rules"},
    {title: "Reports", link: "/reports"},
];
const imgurl = [footer1, footer2, footer3, footer4, footer5, footer6];

const footerImageUrl = [

    {
        link: "https://www.bis.gov.in/",
        image: bis,
        name: "BUREAU OF INDIA STANDARDS(BIS)",  
    },
    {
        link: "https://www.nth.gov.in/",
        image: nth,
        name: "NATIONAL TEST HOUSE(NTH)", 
    },
    // {
    //     link: "https://ncdrc.nic.in/",
    //     image: footer2
    // },
    // {
    //     link: "https://consumerhelpline.gov.in/nch.php",
    //     image: footer3
    // },

    {
        link: "https://jagograhakjago.gov.in/",
        image: jgj,
        name: "JAGO GRAHAK JAGO",
    },

    {
        link: "https://jagograhakjago.gov.in/cwf/Default.aspx",
        image: emblem,
        name: "CONSUMER WELFARE FUND(CWF)", 
    },

    {
        link: "https://nccf-india.com/",
        image: nccf,
        name: "NATIONAL COOPERATIVE CONSUMERS' FEDERATION",  
    },
    {
        link: "https://lm.doca.gov.in/",
        image: emblem,
        name: "ONLINE LEGAL METROLOGY APPLICATIONS",
    },

    {
        link: "https://doca.gov.in",
        image: emblem,
        name: "DEPARTMENT OF CONSUMER AFFAIRS",
    },
]




export default function Footer({margin = true}: { margin?: boolean }) {



    const plugin = React.useRef(
        AutoScroll({ speed: 1, stopOnInteraction: true, stopOnMouseEnter:true})
    )

    const navigate = useNavigate();
    const location = useLocation();

    const handleMenuClick = (link: string) => {
    const isHomePage = window.location.pathname === "/";
    if (isHomePage) {
      const sectionId = link.replace("#", "");
      const section = document.getElementById(sectionId);
      if (section) {

        section.scrollIntoView({ behavior: "smooth" });
      }
    } else {

      navigate("/", { state: { scrollToSection: link } });
    }
    };

    useEffect(() => {
        const scrollToSection = location.state?.scrollToSection;
        if (scrollToSection) {
        const sectionId = scrollToSection.replace("#", "");
        const section = document.getElementById(sectionId);
        if (section) {
        section.scrollIntoView({ behavior: "smooth" });
        }
        }
        }, [location]);


    return (
        <footer
            id="footer"
            style={{backgroundImage: `url(${footerBg})`}}
            className={cn("bg-no-repeat bg-cover bg-center section-padding relative bg-black ", (margin) ? "mt-10 md:mt-20" : "")}
        >
            <div className="absolute top-[-10rem] right-0">
                <img src={footerBot}/>
            </div>
            {/*bg-[rgba(34,36,41,0.5)] flex items-center  gap-32  py-4 px-8 overflow-x-auto no-scrollbar*/}
            <div
                className="container mt-5">

                <Carousel
                    //@ts-ignore
                    plugins={[plugin.current]}
                    onMouseLeave={()=>{plugin.current.play()}}
                    opts={{
                        align: "start",
                        loop: true,
                    }}
                    className="bg-[rgba(34,36,41,0.5)]">
                    <CarouselContent className=" flex items-center gap-8 lg:gap-20 xl:gap-28 py-4 px-8"> {footerImageUrl.map((item, i) => (
                        <CarouselItem key={i} className="flex-none">
                        <a className="flex flex-row items-center gap-2 lg:gap-4 h-[2.5rem] lg:h-[3.875rem]"
                            href={item.link} target="_blank" >
                            <img
                                src={item.image}
                                alt={"" + i + "logo"}
                                className="aspect-auto h-full"
                            />
                            <h6 className="text-xs md:text-sm lg:text-base text-white max-w-[20ch]">{item.name}</h6>
                        </a>
                        </CarouselItem>
                    ))}
                        {footerImageUrl.map((item, i) => (
                            <CarouselItem key={i} className="flex-none">
                            <a className="flex flex-row items-center gap-2 lg:gap-4 h-[2.5rem] lg:h-[3.875rem]"
                                href={item.link} target="_blank" >
                                <img
                                    src={item.image}
                                    alt={"" + i + "logo"}
                                    className="aspect-auto h-full"
                                />
                                <h6 className="text-xs md:text-sm lg:text-base text-white max-w-[20ch]">{item.name}</h6>
                            </a>
                            </CarouselItem>
                        ))}
                    </CarouselContent>
                </Carousel>


                <div className="my-5 mt-12 grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div className="flex flex-col  gap-4">
                        {/* <div>
                            <img src={logo} alt="logo" className="w-[200px] h-[90px] my-auto object-center"/>
                        </div> */}

                        <p className="text-white text-sm">
                            Legal Metrology is the application of legal <br/>
                            requirements to measurements and measuring <br/>
                            instruments.
                        </p>
                        <div className=" flex gap-2">
                            <IoIosGlobe className="fill-white"/>
                            <FaLinkedin className="fill-white"/>
                            <FaFacebookSquare className="fill-white"/>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3">
                        <div>
                            <h4 className="text-[#FFAA17]">Explore</h4>
                            <div className="max-w-16 h-[2px] bg-white mt-2">
                                <div className="bg-[#FFAA17] h-[2px] w-4"></div>
                            </div>
                        </div>
                        <ul className=" flex flex-col gap-2">
                            {menuitem.map((item, i) => (
                                <li key={i} className="text-sm text-white cursor-pointer" 
                                onClick={() => handleMenuClick(item.link)}
                                >
                                    {item.title}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="flex flex-col gap-3">
                        <div>
                            <h4 className="text-[#FFAA17]">Quick Links</h4>
                            <div className="max-w-16 h-[2px] bg-white mt-2">
                                <div className="bg-[#FFAA17] h-[2px] w-4"></div>
                            </div>
                        </div>
                        <ul className=" flex flex-col gap-2 mb-7">
                            {quicklinks.map((item, i) => (
                                <ActiveLinkComponent url={item.link}><li key={i} className="flex flex-row items-center gap-2">
                                    <IoIosSend className="fill-[#FFAA17]"/>
                                    <span className="text-sm text-white">{item.title}</span>
                                </li></ActiveLinkComponent>
                            ))}
                        </ul>
                    </div>
                </div>
                <hr className="h-[.1rem] bg-white my-2"/>
                <div className=" flex items-center justify-center gap-2">
                    {/* <div className="gap-3"> */}
                    <a href="https://www.nic.in/" target="_blank"><img src={footer8} alt=""/></a>
                    <p className="text-sm text-white">
                        Site Designed, Developed and Maintained By National Informatics
                        Centre, Ministry of Electronics and IT, Govt. of India.
                    </p>
                    {/* </div> */}
                </div>
            </div>
        </footer>
    );
}