import loadingGIF from '../../Assets/image/loading.gif'


export default function LoadingScreen() {
    return (
        <div className="w-screen h-screen grid place-items-center place-content-center bg-white">
            <div>
                <img src={loadingGIF} alt="loading"/>
            </div>
        </div>
    )
}