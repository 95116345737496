import React from 'react'
import EnforcementMain from '../Components/EmforcementComponent/EnforcementMain'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'

const EnforcementPage = () => {
  return (
    <>
      <Navbar />
      <EnforcementMain/>
        <div className="pt-36 md:pt-20 bg-theme_bg"></div>
      <Footer margin={false}/>
        
    </>
  )
}

export default EnforcementPage