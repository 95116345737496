import { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import ApprovalCharts from "./charts/approval.charts";
import SectorApprovalCharts from "./charts/sector-approval.charts";

export default function InsightsComponent(){

    const {t, i18n} = useTranslation('home');

  const [language, setLanguage] = useState("en");

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

    return (
        <section id="graphs" className="section-padding bg-[#F4F5F8]">
            <div className="container">
                <h1 className="text-center">
                    {t('letGetInsights')}
                    {/* Lets Get <span className="text-theme_yellow">Insights</span> */}
                </h1>
                <div className="grid grid-cols-1 lg:grid-cols-2 section-margin gap-4 xl:gap-8">
                    <SectorApprovalCharts/>
                    <ApprovalCharts/>
                </div>

            </div>
        </section>
    )
}