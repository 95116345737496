import styles from "./landing.module.scss";
import { useTranslation } from 'react-i18next';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";

import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { cn } from "../../lib/utils";
import landingcurve from "../../Assets/image/background/landing-curve.png"
import { useState, useEffect } from "react";
import LicenceSearchComponent from "./LicenceSearchComponent";
export default function LandingComponent() {

  const {t, i18n} = useTranslation('home');

  const [language, setLanguage] = useState("en");

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);


  return (
    <section id="landing_section" className={styles.landingSection}>
      <div className={cn("grid grid-cols-1 md:grid-cols-2 py-8 md:py-4 ")}>
        <div
          className={cn(
            "h-[615px] w-full overflow-hidden relative hidden md:grid"
          )}
        >
          <img
            className={
              "absolute h-full w-full min-w-[805px] top-1/2 right-0 -translate-y-1/2 object-center"
            }
            src={landingcurve}
            alt={"landing-curve"}
            width={805}
            height={615}
          />
        </div>
        <div className="grid place-content-center flex-grow mx-8">
          <div className="w-full max-w-[42ch] 2xl:max-w-[50ch] flex flex-col gap-4  justify-between h-[400px] md:h-[250px]">
            <h1 className="text-5xl">
              {t('heading')}
              {/* Experience swift, <span className="text-theme_yellow">hassle-free</span>{" "}
              online certifications and{" "}
              <span className="text-theme_yellow">approvals</span> */}
            </h1>
            
            <div>
              <p className="font-medium">{t('certificateLable')}</p>
              <div className="flex flex-col lg:flex-row gap-3">
                <Select>
                  <SelectTrigger className="w-[220px] bg-white">
                    <SelectValue placeholder="Select" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="modalApprovalSystem">Modal Approval System</SelectItem>
                    <SelectItem value="certificateOfImporters">Certificates of importers</SelectItem>
                    <SelectItem value="packagedCommuditiesRegistration">Packaged Commudities Registration</SelectItem>
                    <SelectItem value="directorsOfCompaniesNominated">Directors of the Companies Nominated</SelectItem>
                  </SelectContent>
                </Select>
                <LicenceSearchComponent t={t}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
